import React from 'react';

// Main App component that contains all sections
function App() {
  return (
    <div>

      <main>
        <section id="home">
          <h2>Welcome to Our Website</h2>
        </section>

        <section id="about">
          <h2>About Us</h2>
          <p>Guntaka Ventures, LLC is a company committed to developing products and apps that help people.</p>
        </section>

        <section id="terms">
          <h2>Terms of Service</h2>
          <p>Please read our terms of service carefully before using our website.</p>
          <p>Terms of Service

Last Updated: 5/22/2024

1. Acceptance of Terms
By accessing and using Warhorse Gear Training App, you agree to comply with and be bound by these Terms of Service. If you do not agree to these Terms, please do not use the App.

2. Changes to Terms
We reserve the right to modify these Terms at any time. We will notify you of any changes by updating the "Last Updated" date at the top of these Terms. Your continued use of the App after any changes indicates your acceptance of the new Terms.

3. Description of Service
Warhorse Gear Training App is a comprehensive training platform designed for soccer enthusiasts and professionals. Our app offers a series of training modules that guide users through various drills and exercises using the Warhorse Gear Soccer Mat. Additionally, users can record their practice sessions and utilize our advanced video analysis tools to review and improve their performance. Whether you're looking to enhance your skills, track your progress, or receive detailed feedback, Warhorse Gear Training App provides the resources you need to take your soccer training to the next level..

4. User Accounts
To use certain features of the App, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account.

5. Privacy Policy
Your use of the App is also governed by our Privacy Policy, which can be found on guntakaventures.com. By using the App, you consent to the practices described in the Privacy Policy.

6. User Conduct
You agree not to use the App for any unlawful purpose or in any way that interferes with the proper functioning of the App. This includes, but is not limited to:

Posting or transmitting any content that is illegal, harmful, threatening, abusive, defamatory, or otherwise objectionable.
Using the App to harm minors in any way.
Attempting to interfere with the security or integrity of the App or any networks connected to the App.

7. Termination
We may terminate or suspend your account and access to the App, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the App will immediately cease.

8. Disclaimers
The App is provided on an "as-is" and "as-available" basis. Guntaka Ventures, LLC makes no warranties, expressed or implied, about the reliability, accuracy, or availability of the App. We do not warrant that the App will be uninterrupted or error-free.

9. Limitation of Liability
To the fullest extent permitted by law, Guntaka Ventures, LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the App.

10. Governing Law
These Terms shall be governed by and construed in accordance with the laws of the state of California, without regard to its conflict of law principles.

11. Contact Information
If you have any questions about these Terms, please contact us at info@guntakaventures.com. </p>
        </section>

        <section id="privacy">
          <h2>Privacy Policy</h2>
          <p>Your privacy is important to us. Please read our privacy policy to understand how we handle your data.</p>
          <p>Privacy Policy

Last Updated: 5/22/2024

Introduction
Guntaka Ventures, LLC is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use Warhorse Gear Training App. By using the App, you agree to the collection and use of information in accordance with this policy.

Information We Collect
We may collect and process the following types of information:

Personal Information
Account Information: Username, password, profile information.
Usage Data
Log Data: IP address, browser type, operating system, referring/exit pages, date/time stamps.
App Usage Information: Features used, actions taken, and other user interactions.
Device Information
Device Details: Device type, unique device identifier, operating system version, mobile network information.
How We Use Your Information
We use the information we collect for various purposes, including:

To provide, operate, and maintain the App.
To improve, personalize, and expand the App.
To understand and analyze how you use the App.
To develop new products, services, features, and functionality.
To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the App, and for marketing and promotional purposes.
To find and prevent fraud.
To comply with legal obligations.

Sharing Your Information
We may share your information in the following situations:

With Service Providers: We may share your information with third-party service providers to perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing efforts.

For Legal Reasons: We may disclose your information if required by law, to respond to legal processes, to protect our rights, to investigate fraud, or in connection with a corporate transaction such as a merger, sale of assets, or bankruptcy.

With Your Consent: We may share your information with your consent or at your direction.
Security of Your Information

We use administrative, technical, and physical security measures to protect your personal information. While we strive to protect your information, no method of transmission over the Internet or method of electronic storage is 100% secure.

Your Data Protection Rights
Depending on your location, you may have the following rights regarding your personal data:

Access: The right to request copies of your personal information.
Rectification: The right to request correction of any information you believe is inaccurate or incomplete.
Erasure: The right to request that we erase your personal information, under certain conditions.
Restrict Processing: The right to request that we restrict the processing of your personal data, under certain conditions.
Object to Processing: The right to object to our processing of your personal data, under certain conditions.
Data Portability: The right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
Children's Privacy
The App is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information from our records.

Changes to This Privacy Policy
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.

Contact Us
If you have any questions about this Privacy Policy, please contact us at:

Email: info@guntakaventures.com</p>
        </section>

        <section id="contact">
          <h2>Contact Us</h2>
          <p>Reach out to us at info@guntakaventures.com</p>
        </section>
      </main>
      
      <footer>
        <p>© 2024 Guntaka Ventures, LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
